<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>权限设置</h5>
    </div>
    <div class="line"></div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column prop="roleId" label="ID" width="140" />
        <el-table-column prop="roleName" label="角色" width="90" />
        <el-table-column prop="menuList" label="权限(可操作菜单)" />
        <el-table-column prop="updateTime" label="创建时间" width="180" />
        <el-table-column width="60" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button v-if="scope.row.roleId != 1" type="primary" icon="el-icon-edit" circle
                @click="editTest(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="pageSize"
      :current-page="pageNum" layout="total,sizes, prev, pager, next" :total="total">
    </new-pagination>
    <!--编辑-->
    <el-drawer title="修改角色菜单权限" :visible.sync="editDialog" direction="rtl" custom-class="drawClass">
      <div class="content">
        <el-tree v-if="editDialog" :data="menuTree" ref="tree" show-checkbox node-key="id"
          :default-checked-keys="checkedMenuList" :props="defaultProps">
        </el-tree>
        <el-button @click="submit">确定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import api from "@/api/index";
export default {
  data() {
    return {
      tableData: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      editDialog: false,
      operateBox: {
        title: "修改",
        operate: "add",
        id: null,
      },
      //菜单树
      menuTree: [],
      checkedMenuList: [1],
      defaultProps: {
        children: "children",
        label: "label",
      },
      checkedRoleId: "",
    };
  },
  created() {
    this.getAllMenu();
    this.getAllMenuTree(1);
  },
  methods: {
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageSize = val;
      this.getAllMenu();
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getAllMenu();
    },
    editTest(row) {
      this.checkedRoleId = row.roleId;
      this.getMenuTreeById(row.roleId);
    },
    //获取列表
    async getAllMenu() {
      let p = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      let res = await api.getAllMenu(p);

      if (res.code == 200) {
        res.rows.forEach((item) => {
          item.menuList = "";
          if (item.menus && item.menus.length > 0) {
            item.menus.forEach((e, index) => {
              if (index == item.menus.length - 1) {
                item.menuList = item.menuList + e.menuName;
              } else {
                item.menuList = item.menuList + e.menuName + ",";
              }
            });
          } else {
            item.menuList = "";
          }
        });
        console.log(res.rows);
        this.tableData = res.rows;
        this.total = res.total - 0;
      } else {
        this.tableData = [];
        this.total = 0;
      }
    },
    //获取全部角色菜单树
    async getAllMenuTree(id) {
      let p = {
        roleId: id,
      };
      let res = await api.getMenuTreeById(p);
      if (res.code == 200) {
        res.menus.forEach(item => {
          if (item.id == 1) {
            item.disabled = true
          }
        })
        this.menuTree = res.menus;
      } else {
        this.menuTree = [];
      }
    },
    //获取当前角色已选中菜单树
    async getMenuTreeById(id) {
      let p = {
        roleId: id,
      };
      let res = await api.getMenuTreeById(p);
      console.log('权限', res);
      if (res.code == 200 && res.checkedKeys != '') {
        const filteredArray = res.menus.filter(obj => obj.hasOwnProperty('children'));
        const idArray = filteredArray.map(obj => obj.id);
        const result = res.checkedKeys.filter((element) => !idArray.includes(element));
        this.checkedMenuList = result
      } else {
        this.checkedMenuList = [1];
      }
      this.editDialog = true;
    },
    async submit() {
      let p = {
        roleId: this.checkedRoleId,
        menuIds: [...this.$refs.tree.getCheckedKeys(), ...this.$refs.tree.getHalfCheckedKeys()],
      };
      let res = await api.editRole(p);
      if (res.code == 200) {
        this.$message.success("修改角色权限成功");
        this.editDialog = false;
        this.getAllMenu();
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style scoped lang="less">
/*页面特殊样式*/
.one_tag,
.two_tag,
.three_tag {
  padding: 5px 12px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  color: #fff;
}

.one_tag {
  background: #c60c00;
  background: -webkit-linear-gradient(to bottom, #eb1610, #c66334);
  background: linear-gradient(to bottom, #eb1600, #c65f00);
}

.two_tag {
  background: #eb6601;
  background: -webkit-linear-gradient(to bottom, #eb6601, #c6aa07);
  background: linear-gradient(to bottom, #eb6601, #c6aa07);
}

.three_tag {
  background: #ebd007;
  background: -webkit-linear-gradient(to bottom, #ebd007, #bec606);
  background: linear-gradient(to bottom, #ebd007, #bec606);
}

/deep/.drawClass {
  .el-drawer__body {
    height: 100%;
    padding: 0 10px 20px 10px;

    .content {
      height: 100%;

      .el-tree {
        height: 90%;
      }
    }
  }
}

.tbale_box {
  height: calc(100% - 100px);
}
</style>
